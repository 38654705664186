import { Grid, Avatar } from "@mui/material";

export default function BoardMember(props) {
  return (
    <Grid container spacing={2} style={{ marginBottom: 20 }}>
      <Grid item xs={12} md={3} style={{ textAlign: "center" }}>
        {props.picture ? (<img src={props.picture} style={{ maxWidth: 150, margin: "auto" }} alt={props.name + " picture"} />) : (
          <Avatar src="/broken-image.jpg" style={{ width: 150, height: 150, margin: "auto" }} />
        )}
      </Grid>
      <Grid item xs={12} md={9}>
        <h2 className="yellow">{props.name}</h2>
        <p>{props.bio}</p>
        {props.email && (<p>Contact me at <a href={"mailto:" + props.email}>{props.email}</a> {props.phone && (<>or by phone at <a href={"tel:" + props.phone}>{props.phone}</a></>)}</p>)}
      </Grid>
    </Grid>
  );
}