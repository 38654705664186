import './footer.scss';

export default function Footer() {
  return (
    <div className="footer">
      <div className="copy" style={{ marginTop: 2 }}>
        <p>Copyright {new Date().getFullYear()} MLUA</p>
        <p>Site by<a href="https://minnedev.com" target="_blank" rel="noreferrer">Minnedev</a></p>
        <p>Designed by Julie Cestaro</p>
      </div>
    </div>
  );
}